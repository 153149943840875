import { Button, Icon, tokens } from "@adasupport/byron";
import React, { type ReactNode, useState } from "react";
import { useBetween } from "use-between";

import { openModalAction } from "actions/modal";
import { type ModalProps as LegacyModalProps } from "actions/modal/types";
import { type ThunkAction } from "actions/types";
import {
  Flex,
  FlexColumn,
  HSpace4,
  ModalContent,
  Text,
  VSpace2,
  VSpace4,
} from "components/Common";

/** @deprecated */
export function openConfirmationModalAction(
  props: LegacyModalProps,
): ThunkAction {
  return (dispatch) => {
    dispatch(openModalAction("MODAL_WARNING", props));
  };
}

export { closeModalAction } from "actions/modal";

interface ModalProps {
  title: ReactNode;
  onClose?: () => void;
}

/** To be used with `useBetween` hook to persist values across application */
const useModalContent = () => {
  const [content, setContent] = useState<ReactNode>(null);
  const [props, setProps] = useState<ModalProps>({ title: "" });
  const [isShown, setIsShown] = useState<boolean>(false);

  return { content, setContent, props, setProps, isShown, setIsShown };
};

export const useModal = () => {
  const { content, setContent, props, setProps, isShown, setIsShown } =
    useBetween(useModalContent);

  return {
    modal: {
      props,
      isShown,
      content,
    },

    openModal: (modalProps: ModalProps, modalContent: ReactNode) => {
      setProps(modalProps);
      setContent(modalContent);
      setIsShown(true);
    },

    closeModal: () => {
      setIsShown(false);
    },
  };
};

export const useConfirmationModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    openConfirmationModal: (props: LegacyModalProps) =>
      openModal(
        { title: null },
        <>
          <ModalContent width={480}>
            <Flex>
              <FlexColumn>
                <Icon.Exclamation
                  width={24}
                  color={tokens.colors.text.negative}
                />
                <HSpace4 />
              </FlexColumn>
              <FlexColumn grow={1} shrink={1}>
                <Text block size="large" weight="semibold">
                  {props.title}
                </Text>
                <VSpace2 />
                <Text block size="small">
                  {props.message}
                </Text>
              </FlexColumn>
            </Flex>
          </ModalContent>

          <VSpace2 />
          <Flex>
            <HSpace4 />
            <Flex stretch gap justifyContent="flex-end">
              {props.actions?.map((action) => (
                <Button
                  key={action.title}
                  onClick={action.onClick}
                  variant={(() => {
                    switch (action.buttonTint) {
                      case "alert":
                        return "danger";
                      case "primary":
                        return "primary";
                      default:
                        return "tertiary";
                    }
                  })()}
                  text={action.title}
                />
              ))}
            </Flex>
            <HSpace4 />
          </Flex>
          <VSpace4 />
        </>,
      ),
    closeModal,
  };
};
