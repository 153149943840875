import { Icon } from "@adasupport/byron";
import { Record } from "immutable";
import { type ReactElement, type ReactNode } from "react";

export type FilterType =
  | "answerId"
  | "language"
  | "browser"
  | "device"
  | "channel"
  | "platform"
  | "interactionType"
  | "feedback"
  | "agentIds"
  | "blockType"
  | "isTestUser"
  | "includeIntros"
  | "locked"
  | "variable"
  | "isAgentReview"
  | "engaged"
  | "handoff"
  | "csatscore"
  | "conversationTopics"
  | "generatedTopics"
  | "generatedTopicsV2"
  | "generated_topic_id"
  | "gen_topic_id"
  | "arStatus"
  | "coachingApplied"
  | "divider";

export const booleanFilterTypes: FilterType[] = [
  "isTestUser",
  "locked",
  "includeIntros",
  "isAgentReview",
  "engaged",
  "handoff",
];

export const OVERVIEW_URL = "/analytics/overview";

export interface SelectOption {
  label: string;
  value: string;
  icon?: string | (() => ReactElement);
  groupName?: string;
  subMenu?: SelectOption[];
  customSubMenu?: ReactNode;
  customSelectedElement?: ReactNode;
}

export enum Operators {
  IS,
  ISNOT,
  BEGINSWITH,
  ENDSWITH,
  CONTAINS,
  ISSET,
  ISNOTSET,
}

export enum ReportTypes {
  CONVERSATIONS_TOPICS_OVERVIEW = "conversations_topics_overview",
}

export const booleanOperatorTypes: Operators[] = [
  Operators.ISSET,
  Operators.ISNOTSET,
];

export interface OperatorOption {
  label: string;
  icon: string;
  value: Operators;
}

interface FilterItemAttributes {
  filterType: FilterType | null;
  operator: Operators;
  value: string[] | boolean | string | null;
  variable: { id: string; name: string; scope: string; type: string } | null;
  review?: string | null;
}

/**
 * Filter state
 */
export class FilterItem extends Record<FilterItemAttributes>({
  filterType: null,
  operator: Operators.IS,
  value: true,
  variable: null,
  review: null,
}) {}

export const supportedFiltersConfig: {
  [key: string]: {
    label: string;
    icon: string | (() => JSX.Element);
    operators: Operators[];
    // The following field contains a pair of possible values, 1st negative value, 2nd is positive
    // value. This field only exists if the filter is of boolean type (see type of FilterItem.value)
    possibleValues?: unknown[];
    type?: string;
  };
} = {
  isTestUser: {
    label: "Include Test User",
    icon: Icon.User,
    operators: [Operators.IS],
    possibleValues: [false, undefined],
  },
  includeIntros: {
    label: "Include Intros",
    icon: "Chat",
    operators: [Operators.IS, Operators.ISNOT],
    possibleValues: [false, true, undefined],
  },
  locked: {
    label: "Exclude Locked Answers",
    icon: "Lock",
    operators: [Operators.IS, Operators.ISNOT],
    possibleValues: [undefined, false],
  },

  answerId: {
    label: "Answers",
    icon: "Categories",
    operators: [Operators.IS], // IS_NOT operation will make the request too large.
  },
  blockType: {
    label: "Block",
    icon: "Shapes",
    operators: [Operators.IS, Operators.ISNOT],
  },
  language: {
    label: "Language",
    icon: Icon.Language,
    operators: [Operators.IS, Operators.ISNOT],
  },
  browser: {
    label: "Browser",
    icon: Icon.Window,
    operators: [Operators.IS, Operators.ISNOT],
  },
  device: {
    label: "Device",
    icon: Icon.Laptop,
    operators: [Operators.IS, Operators.ISNOT],
  },
  channel: {
    label: "Channel",
    icon: Icon.Channels,
    operators: [Operators.IS, Operators.ISNOT],
  },
  platform: {
    label: "Platform",
    icon: "Cloud",
    operators: [Operators.IS, Operators.ISNOT],
  },
  interactionType: {
    label: "Interaction Type",
    icon: "CircleArrowUp",
    operators: [Operators.IS, Operators.ISNOT],
  },
  feedback: {
    label: "Reason for rating",
    icon: Icon.MessageLove,
    operators: [Operators.IS, Operators.ISNOT],
  },
  agentIds: {
    label: "Live agent",
    icon: Icon.LiveAgent,
    operators: [Operators.IS, Operators.ISNOT],
  },
  // Variable filter config is defined in AnalyticsReportFilter component
  variable: {
    label: "Variable",
    icon: "Asterisk",
    operators: [
      Operators.IS,
      Operators.ISNOT,
      Operators.BEGINSWITH,
      Operators.ENDSWITH,
      Operators.CONTAINS,
      Operators.ISSET,
      Operators.ISNOTSET,
    ],
  },
  isAgentReview: {
    label: "Agent review",
    icon: Icon.LiveAgent,
    operators: [Operators.IS, Operators.ISNOT],
    possibleValues: [false, true],
  },
  engaged: {
    label: "Engaged",
    icon: Icon.MessageCheck,
    operators: [Operators.IS, Operators.ISNOT],
    possibleValues: [false, true],
  },
  handoff: {
    label: "Handoff",
    icon: "MessageArrowUp",
    operators: [Operators.IS, Operators.ISNOT],
    possibleValues: [false, true],
  },
  csatscore: {
    label: "CSAT",
    icon: "MessageHeart",
    operators: [Operators.IS],
  },
  // Conversation Topics filter config is defined in AnalyticsReportFilter component
  conversationTopics: {
    label: "Conversation Topic",
    icon: Icon.Topic,
    operators: [Operators.IS],
  },
  generatedTopics: {
    label: "Generated Topic",
    icon: "MessageDots",
    operators: [Operators.IS, Operators.ISNOT],
  },
  arStatus: {
    label: "AR Classification",
    icon: Icon.Check,
    operators: [Operators.IS],
  },
  coachingApplied: {
    label: "Coaching Applied",
    icon: Icon.Help,
    operators: [Operators.IS, Operators.ISNOT],
  },
  divider: {
    label: "",
    icon: "",
    operators: [],
    type: "divider",
  },
};
