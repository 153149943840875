import { Icon } from "@adasupport/byron";
import React from "react";

import { RULE_DSL, type RuleDefinition } from "services/rules";
import { type Variable } from "services/variables";
import { IconWithLabel } from "source/components/Common/ByronSelectUtilities";

export const NUMBER_OPERATOR_OPTIONS = [
  {
    label: <IconWithLabel text="More than" icon={Icon.GreaterThan} />,
    value: RULE_DSL.GREATER_THAN,
  },
  {
    label: <IconWithLabel text="Less than" icon={Icon.LessThan} />,
    value: RULE_DSL.LESS_THAN,
  },
  {
    label: <IconWithLabel text="Is" icon={Icon.Equals} />,
    value: RULE_DSL.IS,
  },
  {
    label: <IconWithLabel text="Is not" icon={Icon.DoesNotEqual} />,
    value: RULE_DSL.IS_NOT,
  },
  {
    label: <IconWithLabel text="Is set" icon={Icon.IsNotNull} />,
    value: RULE_DSL.IS_SET,
  },
  {
    label: <IconWithLabel text="Is not set" icon={Icon.IsNull} />,
    value: RULE_DSL.IS_NOT_SET,
  },
];

export const BOOLEAN_OPERATOR_OPTIONS = [
  {
    label: <IconWithLabel text="Is" icon={Icon.Equals} />,
    value: RULE_DSL.IS,
  },
  {
    label: <IconWithLabel text="Is not" icon={Icon.DoesNotEqual} />,
    value: RULE_DSL.IS_NOT,
  },
  {
    label: <IconWithLabel text="Is set" icon={Icon.IsNotNull} />,
    value: RULE_DSL.IS_SET,
  },
  {
    label: <IconWithLabel text="Is not set" icon={Icon.IsNull} />,
    value: RULE_DSL.IS_NOT_SET,
  },
];

export const STRING_OPERATOR_OPTIONS = [
  {
    label: <IconWithLabel text="Is" icon={Icon.Equals} />,
    value: RULE_DSL.IS,
  },
  {
    label: <IconWithLabel text="Is not" icon={Icon.DoesNotEqual} />,
    value: RULE_DSL.IS_NOT,
  },
  {
    label: <IconWithLabel text="Starts with" icon={Icon.StartsWith} />,
    value: RULE_DSL.STARTS_WITH,
  },
  {
    label: <IconWithLabel text="Ends with" icon={Icon.EndsWith} />,
    value: RULE_DSL.ENDS_WITH,
  },
  {
    label: <IconWithLabel text="Contains" icon={Icon.Contains} />,
    value: RULE_DSL.CONTAINS,
  },
  {
    label: <IconWithLabel text="Does not contain" icon={Icon.DoesNotContain} />,
    value: RULE_DSL.NOT_CONTAINS,
  },
  {
    label: <IconWithLabel text="Is set" icon={Icon.IsNotNull} />,
    value: RULE_DSL.IS_SET,
  },
  {
    label: <IconWithLabel text="Is not set" icon={Icon.IsNull} />,
    value: RULE_DSL.IS_NOT_SET,
  },
];

export const LIST_OPERATOR_OPTIONS = [
  {
    label: <IconWithLabel text="Is set" icon={Icon.IsNotNull} />,
    value: RULE_DSL.IS_SET,
  },
  {
    label: <IconWithLabel text="Is not set" icon={Icon.IsNull} />,
    value: RULE_DSL.IS_NOT_SET,
  },
  {
    label: <IconWithLabel text="Contains" icon={Icon.Contains} />,
    value: RULE_DSL.CONTAINS,
  },
  {
    label: <IconWithLabel text="Does not contain" icon={Icon.DoesNotContain} />,
    value: RULE_DSL.NOT_CONTAINS,
  },
];

export const LIST_NUMBER_OF_ITEMS_OPERATOR_OPTIONS = [
  {
    label: <IconWithLabel text="Is" icon={Icon.Equals} />,
    value: RULE_DSL.LENGTH_IS,
  },
  {
    label: <IconWithLabel text="More than" icon={Icon.GreaterThan} />,
    value: RULE_DSL.LENGTH_GREATER_THAN,
  },
  {
    label: <IconWithLabel text="Less than" icon={Icon.LessThan} />,
    value: RULE_DSL.LENGTH_LESS_THAN,
  },
];

export const DEFAULT_STRING_ARGUMENT = "";
export const DEFAULT_NUMBER_ARGUMENT = 0;
export const DEFAULT_BOOLEAN_ARGUMENT = true;

export const getDefaultRightArgumentValue = (
  variableType: Variable["_type"] | undefined,
  ruleName: RuleDefinition["name"],
  existingValue: string | number | boolean = "",
) =>
  (() => {
    if (ruleName === RULE_DSL.IS_SET || ruleName === RULE_DSL.IS_NOT_SET) {
      return undefined;
    }

    switch (variableType) {
      case "long":
        return typeof existingValue === "number"
          ? existingValue
          : DEFAULT_NUMBER_ARGUMENT;

      case "bool":
        return typeof existingValue === "boolean"
          ? existingValue
          : DEFAULT_BOOLEAN_ARGUMENT;

      // With list variables it's a bit more complex:
      case "list": {
        // When comparing list length, right argument must be a number
        if (
          ruleName === RULE_DSL.LENGTH_IS ||
          ruleName === RULE_DSL.LENGTH_LESS_THAN ||
          ruleName === RULE_DSL.LENGTH_GREATER_THAN
        ) {
          return typeof existingValue === "number"
            ? existingValue
            : DEFAULT_NUMBER_ARGUMENT;
        }

        // Otherwise – a string
        return typeof existingValue === "string"
          ? existingValue
          : DEFAULT_STRING_ARGUMENT;
      }

      default:
        return typeof existingValue === "string"
          ? existingValue
          : DEFAULT_STRING_ARGUMENT;
    }
  })();
