import * as Popover from "@radix-ui/react-popover";
import React, { useRef, useState } from "react";

import { NO_OP_FUNCTION } from "services/helpers";

import { getVariableValues } from "./services";
import * as S from "./styles";

interface Props {
  value: string;
  onChange: (newVal: string) => void;
  variableId: string | undefined;
  placeholder?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
}

export function InputWithVariableAutocompleteDropdown({
  value,
  onChange,
  variableId,
  placeholder,
  isInvalid = false,
  isDisabled = false,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [variableValueOptions, setVariableValueOptions] = useState<string[]>(
    [],
  );
  const abortController = useRef<AbortController>();

  async function fetchVariableValues(prefix: string) {
    if (variableId) {
      abortController.current?.abort();
      abortController.current = new AbortController();
      const values = await getVariableValues(
        variableId,
        prefix,
        abortController.current.signal,
      );

      setVariableValueOptions(values);

      if (values.length > 0) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }

  return (
    <Popover.Root open={isOpen} onOpenChange={NO_OP_FUNCTION}>
      <Popover.Trigger asChild>
        <S.InputContainer>
          <S.Input
            value={value}
            placeholder={placeholder}
            isInvalid={isInvalid}
            disabled={isDisabled}
            onFocus={async () => {
              await fetchVariableValues(value);
            }}
            onBlur={() => {
              setIsOpen(false);
              abortController.current?.abort();
            }}
            onChange={async (event) => {
              const newVal = event.target.value;
              onChange(newVal);
              await fetchVariableValues(newVal);
            }}
          />
        </S.InputContainer>
      </Popover.Trigger>
      <S.Content
        align="start"
        sideOffset={8}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {variableValueOptions.map((option) => (
          <S.Option
            // This prevents the input's onBlur handler from closing the dropdown before the click can register
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setIsOpen(false);
              onChange(option);
            }}
          >
            {option}
          </S.Option>
        ))}
      </S.Content>
    </Popover.Root>
  );
}
