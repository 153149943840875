import { primitives, tokens } from "@adasupport/byron";
import * as Popover from "@radix-ui/react-popover";
import styled, { css } from "styled-components";

import { cssVariables } from "constants/css";

export const Content = styled(Popover.Content)`
  z-index: ${cssVariables.zIndexModal};
  min-width: 240px;
  max-width: 550px;
  background-color: ${tokens.colors.background.main};
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 0 10px 0 rgb(0 0 0 / 18%);
  border-radius: 8px;
  padding: 12px 0;
`;

export const Option = styled.div`
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  padding: 6px 16px;
  font-size: 14px;

  &:hover {
    background: ${tokens.colors.action.hover};
  }
`;

export const InputContainer = styled.div`
  display: flex;
`;

const inputStyleInvalid = css`
  &,
  &:focus {
    border-color: ${tokens.colors.border.formInputError};
  }
`;

export const Input = styled.input<{
  isInvalid?: boolean;
}>`
  width: 100%;
  padding: 6px ${primitives.spacing.space7} 6px 8px;
  line-height: 22px;
  font-size: 14px;
  font-family: inherit;
  color: ${tokens.colors.text.main};
  background-color: ${tokens.colors.background.main};
  border: 1px solid ${tokens.colors.border.formInput};
  border-radius: 6px;
  transition: 250ms ease;
  white-space: nowrap;

  ${(p) => p.isInvalid && inputStyleInvalid}

  &:focus {
    border-color: ${tokens.colors.border.formInputFocused};
    box-shadow: ${tokens.colors.border.formInputFocusedShadow} 0px 0px 0px 4px;
  }

  &:disabled {
    color: ${tokens.colors.text.muted};
    border-color: ${tokens.colors.border.default};
    cursor: not-allowed;
  }

  &::placeholder {
    font-style: italic;
  }
`;
