import Immutable from "immutable";
import { useMemo } from "react";

import {
  useCreateVariableMutation,
  useEditVariableMutation,
  useGetVariablesQuery,
} from "slices/variables/variablesApi";

import { VariableRecord } from "./records";

export const useVariables = ({ skip }: { skip?: boolean } = {}) => {
  // NOTE: this only gets variables that have already been saved to the db
  // legacy variable code creates VariableRecords in redux before the user saves
  // example: you create a new variable in capture block but haven't saved
  // if you use this hook, you won't have access to that new variable until a save happens
  const { data: variables, refetch } = useGetVariablesQuery(undefined, {
    skip,
  });
  const [createVariable] = useCreateVariableMutation();
  const [editVariable] = useEditVariableMutation();

  const variablesLegacy = useMemo(
    () =>
      variables
        ? Immutable.List(
            variables.map(
              (v) =>
                new VariableRecord({
                  id: v._id,
                  name: v.name,
                  scope: v.scope,
                }),
            ),
          )
        : Immutable.List<VariableRecord>(),
    [variables],
  );

  return {
    variables,
    variablesLegacy,
    refetch,
    createVariable,
    editVariable,
  };
};
